import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Button, Badge } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import SingleVariantComponent from './SingleVariantComponent';
import MultipleVariantComponent from './MultipleVariantComponent';
import { getKategori, getGudang, createProduct } from '../../../Rest/api-produk'; // Import the createProduct API
import './add.css';

const AddProduct = () => {
  const [variantType, setVariantType] = useState('single'); // State for variant type
  const [categories, setCategories] = useState([]); // State for categories
  const [warehouses, setWarehouses] = useState([]); // State for warehouses
  const [selectedWarehouses, setSelectedWarehouses] = useState([]); // State for selected warehouses
  const [productData, setProductData] = useState({
    name: '',
    item_type: 'Produk Fisik',
    is_inventory: false,
    description: '',
    option1_name: '',
    option2_name: '',
    kategori_id: '',
    location_ids: [],
    variants: [],
    images: [],
  });
  const [loading, setLoading] = useState(false); // To handle the loading state

  // Handle variant type change
  const handleVariantChange = (type) => {
    setVariantType(type);
  };

  // Fetch categories and warehouses on component mount
  useEffect(() => {
    const fetchCategoriesAndWarehouses = async () => {
      try {
        const categoryData = await getKategori();
        setCategories(categoryData); // Set fetched categories
        const warehouseData = await getGudang();
        setWarehouses(warehouseData); // Set fetched warehouses
      } catch (error) {
        console.error('Error fetching categories or warehouses:', error);
      }
    };

    fetchCategoriesAndWarehouses();
  }, []);

  // Handle warehouse selection
  const handleAddWarehouse = (warehouse) => {
    if (!selectedWarehouses.includes(warehouse)) {
      setSelectedWarehouses([...selectedWarehouses, warehouse]);
      setProductData((prev) => ({
        ...prev,
        location_ids: [...prev.location_ids, warehouse.id], // Update product data for warehouse
      }));
    }
  };

  // Handle removing warehouse
  const handleRemoveWarehouse = (warehouse) => {
    const updatedWarehouses = selectedWarehouses.filter((w) => w.id !== warehouse.id);
    setSelectedWarehouses(updatedWarehouses);
    setProductData((prev) => ({
      ...prev,
      location_ids: updatedWarehouses.map((w) => w.id), // Remove warehouse from product data
    }));
  };

  // Handle product data change
  const handleProductDataChange = (e) => {
    const { name, value } = e.target;
    setProductData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle variant change for single variant
  const handleSingleVariantChange = useCallback((singleVariant) => {
    setProductData((prev) => ({
      ...prev,
      variants: [singleVariant],
    }));
  }, []);

  // Handle variant change for multiple variants
  const handleMultipleVariantChange = useCallback((multiVariants, optionNames) => {
    setProductData((prev) => ({
      ...prev,
      variants: multiVariants,
      option1_name: optionNames.option1_name, // Set option names from user input
      option2_name: optionNames.option2_name,
    }));
  }, []);

  // Save handler
  const handleSave = async () => {
    setLoading(true); // Start loading
    try {
      const response = await createProduct(productData); // API call to create product
      console.log('Product created successfully:', response);
      // Handle successful product creation (e.g., show a success message or navigate)
      
      setLoading(false); // Stop loading
    } catch (error) {
      console.error('Error creating product:', error);
      // Handle error (e.g., show an error message)
      setLoading(false); // Stop loading
    }
  };

  return (
    <Fragment>
      {/* Breadcrumb */}
      <Breadcrumbs mainTitle="Tambah Produk" parent="Daftar Produk" title="Tambah Produk" />

      {/* Main Container */}
      <Container fluid={true}>
        <Row>
          {/* Left Form Section */}
          <Col sm="8">
            <Card className="square-card">
              <CardBody>
                <Form>
                  <FormGroup>
                    <Label for="name">Nama Produk *</Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Tulis nama produk ..."
                      value={productData.name}
                      onChange={handleProductDataChange}
                    />
                  </FormGroup>

                  {/* Variant Type Selection */}
                  <FormGroup>
                    <Label for="variantOption">Jumlah Varian</Label>
                    <div className="d-flex justify-content-between">
                      <Button
                        color={variantType === 'single' ? 'primary' : 'light'}
                        onClick={() => handleVariantChange('single')}
                      >
                        Satu Varian
                      </Button>
                      <Button
                        color={variantType === 'multiple' ? 'primary' : 'light'}
                        onClick={() => handleVariantChange('multiple')}
                      >
                        Beberapa Varian
                      </Button>
                    </div>
                  </FormGroup>

                  {/* Conditional rendering based on variant type */}
                  {variantType === 'single' ? (
                    <SingleVariantComponent onVariantChange={handleSingleVariantChange} />
                  ) : (
                    <MultipleVariantComponent onVariantsChange={handleMultipleVariantChange} />
                  )}
                </Form>
              </CardBody>
            </Card>
          </Col>

          {/* Right Settings Section */}
          <Col sm="4">
            <Card className="square-card">
              <CardBody>
                <FormGroup>
                  <Label for="item_type">Pilih Jenis Produk</Label>
                  <Input
                    type="select"
                    name="item_type"
                    id="item_type"
                    value={productData.item_type}
                    onChange={handleProductDataChange}
                  >
                    <option>Produk Fisik</option>
                    <option>Produk Digital</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="is_inventory">Pencatatan Inventori</Label>
                  <Input
                    type="select"
                    name="is_inventory"
                    id="is_inventory"
                    value={productData.is_inventory ? 'Dicatat di Inventori' : 'Tidak Dicatat di Inventori'}
                    onChange={(e) =>
                      setProductData((prev) => ({
                        ...prev,
                        is_inventory: e.target.value === 'Dicatat di Inventori',
                      }))
                    }
                  >
                    <option>Tidak Dicatat di Inventori</option>
                    <option>Dicatat di Inventori</option>
                  </Input>
                </FormGroup>

                {/* Category Selection */}
                <FormGroup>
                  <Label for="kategori_id">Pilih Kategori</Label>
                  <Input
                    type="select"
                    name="kategori_id"
                    id="kategori_id"
                    value={productData.kategori_id}
                    onChange={handleProductDataChange}
                  >
                    <option value="">Pilih Kategori</option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>

                {/* Warehouse Selection (Tag-like Behavior) */}
                <FormGroup>
                  <Label for="warehouse">Tersedia Pada Warehouse Berikut</Label>
                  <div>
                    {warehouses.map((warehouse) => (
                      <Button
                        key={warehouse.id}
                        color={selectedWarehouses.includes(warehouse) ? 'primary' : 'light'}
                        className="m-1"
                        onClick={() => handleAddWarehouse(warehouse)}
                      >
                        {warehouse.name}
                      </Button>
                    ))}
                  </div>
                  <div className="mt-3">
                    {selectedWarehouses.length > 0 && (
                      <div>
                        <Label>Warehouse yang Dipilih:</Label>
                        <div>
                          {selectedWarehouses.map((warehouse) => (
                            <Badge key={warehouse.id} color="secondary" className="mr-1">
                              {warehouse.name}
                              <Button close className="ml-2" onClick={() => handleRemoveWarehouse(warehouse)} />
                            </Badge>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </FormGroup>

                <Button color="primary" onClick={handleSave} disabled={loading}>
                  {loading ? 'Saving...' : 'Simpan'}
                </Button>
                <Button color="secondary" className="ml-2">
                  Batal
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AddProduct;
