import React, { Fragment, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Form,
 FormGroup,
  Label,
  Input,
  Spinner,
  ListGroup,
  ListGroupItem
} from 'reactstrap';
import { Breadcrumbs, H5 } from '../../AbstractElements';
import api from '../../Rest/api'; // Adjust the import path as needed

const CreateOrder = () => {
  const [file, setFile] = useState(null);
  const [marketplace, setMarketplace] = useState('');
  const [uploadStatus, setUploadStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [errors, setErrors] = useState([]);

  const onDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles[0];
    setFile({
      file: file,
      name: file.name,
      type: file.type,
      size: file.size
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'text/csv': ['.csv'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
    },
    maxFiles: 1
  });

  const resetForm = () => {
    setFile(null);
    setMarketplace('');
    setUploadStatus('');
    setLoading(false);
    setResponseMessage('');
    setErrors([]);
  };

  const uploadFile = async () => {
    if (!file || marketplace === '') {
      setUploadStatus('failure');
      setResponseMessage('Please select a marketplace and upload a file before submitting.');
      return;
    }
  
    setLoading(true);
    const url = `/orders/upload/${marketplace === '1' ? 'shopee' : 'tiktok'}`;
    const formData = new FormData();
    formData.append('file', file.file);
    formData.append('marketplace_id', marketplace);
  
    try {
      const response = await api.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setUploadStatus('success');
      setResponseMessage(response.data.message);
      setErrors(Array.isArray(response.data.errors) ? response.data.errors : []);
    } catch (error) {
      console.error('Upload failed:', error);
      setUploadStatus('failure');
      setResponseMessage(error.response.data.message || 'Unknown error');
      setErrors(Array.isArray(error.response?.data.errors) ? error.response.data.errors : []);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    if (!loading) {
      uploadFile();
    }
  };

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Buat Pesanan" parent="Pages" title="Buat Pesanan" />
      <Container fluid={true} className='general-widget'>
        <Row className='g-sm-3 height-equal-2 widget-charts'>
          <Col sm="12">
            <Card>
              <CardHeader>
                <H5>Order Details</H5>
              </CardHeader>
              <CardBody>
                {uploadStatus === 'success' || uploadStatus === 'failure' ? (
                  <div>
                    <h5>{uploadStatus === 'success' ? 'Upload Successful!' : 'Upload Failed!'}</h5>
                    <p>{responseMessage}</p>
                    {errors && errors.length > 0 && (
                      <ListGroup>
                        {errors.map((error, index) => (
                          <ListGroupItem key={index}>
                            Order: {error.order_number}, Error: {error.error.message}
                          </ListGroupItem>
                        ))}
                      </ListGroup>
                    )}
                    <Button color="primary" onClick={resetForm}>New Upload</Button>
                  </div>
                ) : (
                  renderUploadForm(getRootProps, getInputProps, isDragActive, file, marketplace, handleSubmit, resetForm, setFile, setMarketplace, loading)
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

const renderUploadForm = (getRootProps, getInputProps, isDragActive, file, marketplace, handleSubmit, resetForm, setFile, setMarketplace, loading) => (
  <Form>
    {!file ? (
      <div {...getRootProps()} style={{ border: '2px dashed gray', padding: '20px', textAlign: 'center' }}>
        <input {...getInputProps()} />
        {isDragActive ? <p>Drop the file here...</p> : <p>Drag 'n' drop a .csv or .xlsx file here, or click to select a file</p>}
      </div>
    ) : (
      <div style={{ textAlign: 'center' }}>
        <p>Uploaded File: {file.name}</p>
        <Button onClick={() => setFile(null)} color="danger">
          Remove File &times;
        </Button>
      </div>
    )}
    <FormGroup>
      <Label for="marketplaceSelect">Marketplace:</Label>
      <Input type="select" name="marketplace" id="marketplaceSelect" value={marketplace} onChange={(e) => setMarketplace(e.target.value)}>
        <option value="">Select Marketplace</option>
        <option value="1">Shopee</option>
        <option value="3">TikTok</option>
      </Input>
    </FormGroup>
    <Button color="primary" onClick={handleSubmit} disabled={loading || marketplace === ''}>
      {loading ? <Spinner size="sm" /> : null} {' '}Send
    </Button>
    <Button color="secondary" onClick={resetForm}>Reset</Button>
  </Form>
);

export default CreateOrder;
