import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import moment from 'moment';
import 'moment/locale/id'; // Import Indonesian locale for moment

moment.locale('id'); // Set the locale to Indonesian

const DetailModal = ({ isOpen, toggle, produksi }) => {
  if (!produksi) return null;

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Detail Produksi</ModalHeader>
      <ModalBody>
        <div style={{ display: 'flex', marginBottom: '20px' }}>
          <div style={{ flex: '1', marginRight: '20px' }}>
            <img 
              src={produksi.produkJadi.product_photo} 
              alt={produksi.produkJadi.name} 
              style={{ width: '50%', borderRadius: '8px', display: 'block', margin: '0 auto' }} 
            />
            <p style={{ textAlign: 'center', marginTop: '10px', fontWeight: 'bold' }}>
              Produk Jadi: {produksi.produkJadi.name}
            </p>
          </div>
          <div style={{ flex: '1' }}>
            <p><strong>Kode Produksi:</strong> {produksi.kode_produksi}</p>
            <p><strong>Tanggal Produksi:</strong> {moment(produksi.tanggal_produksi).format('DD-MM-YYYY')}</p>
            <p><strong>Jumlah Produk Jadi:</strong> {produksi.jumlah_produk_jadi}</p>
            <p><strong>Keterangan:</strong> {produksi.keterangan}</p>

            <p><strong>Bahan Produksi:</strong></p>
            <ul style={{ paddingLeft: '20px' }}>
              {produksi.bahanProduksi.map(bahan => (
                <li key={bahan.id} style={{ marginBottom: '10px' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img 
                      src={bahan.bahan.product_photo} 
                      alt={bahan.bahan.name} 
                      style={{ width: '50px', height: '50px', marginRight: '10px', borderRadius: '5px' }} 
                    />
                    {bahan.bahan.name} - {bahan.jumlah_bahan} {bahan.bahan.satuan}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div style={{ marginTop: '30px' }}>
          
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Tutup</Button>
      </ModalFooter>
    </Modal>
  );
};

export default DetailModal;
