import React, { useState, useEffect } from 'react';
import { FormGroup, Label, Input, Row, Col, Button, Spinner } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import { FaTrash } from 'react-icons/fa';
import { uploadImage } from '../../../Rest/api-produk'; // Import your upload function

const SingleVariantComponent = ({ onVariantChange }) => {
  const [variantData, setVariantData] = useState({
    price: '',
    weight: '',
    sku: '',
    hpp: '',
    images: [],
  });
  const [isUploading, setIsUploading] = useState(false); // State for tracking upload status
  const [uploadError, setUploadError] = useState(null); // State for tracking upload errors

  // Dropzone configuration
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*',
    multiple: true,
    onDrop: async (acceptedFiles) => {
      setIsUploading(true); // Show the loader when uploading
      setUploadError(null); // Clear any previous errors
      for (const file of acceptedFiles) {
        try {
          const response = await uploadImage(file); // Upload the image
          setVariantData((prev) => ({
            ...prev,
            images: [...prev.images, response.url], // Add the uploaded image URL to the variant data
          }));
        } catch (error) {
          console.error('Error uploading image:', error);
          setUploadError('Error uploading image'); // Set error state if upload fails
        } finally {
          setIsUploading(false); // Hide the loader after uploading
        }
      }
    },
  });

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVariantData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle delete image
  const handleDeleteImage = (index) => {
    const updatedImages = [...variantData.images];
    updatedImages.splice(index, 1);
    setVariantData((prev) => ({ ...prev, images: updatedImages }));
  };

  // Trigger the onVariantChange prop when variantData changes
  useEffect(() => {
    onVariantChange(variantData); // Send variant data to the parent component
  }, [variantData, onVariantChange]); // Only trigger when variantData changes

  return (
    <div>
      <Row form>
        <Col md={6}>
          <FormGroup>
            <Label for="price">Harga (Rp) *</Label>
            <Input
              type="number"
              name="price"
              id="price"
              placeholder="Contoh: 10000"
              value={variantData.price}
              onChange={handleInputChange}
              required
            />
            <small className="text-danger">Harga harus diisi</small>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="weight">Berat (g) *</Label>
            <Input
              type="number"
              name="weight"
              id="weight"
              placeholder="Contoh: 100"
              value={variantData.weight}
              onChange={handleInputChange}
              required
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={6}>
          <FormGroup>
            <Label for="sku">Kode SKU</Label>
            <Input
              type="text"
              name="sku"
              id="sku"
              placeholder="Contoh: 000198LKJ"
              value={variantData.sku}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="hpp">HPP (Rp)</Label>
            <Input
              type="number"
              name="hpp"
              id="hpp"
              placeholder="Contoh: 5000"
              value={variantData.hpp}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
      </Row>

      {/* Photo Upload Section with Dropzone */}
      <FormGroup>
        <Label for="productPhotos">Foto Produk (maks. 10 foto)</Label>
        <div
          {...getRootProps()}
          className={`dropzone ${isDragActive ? 'active' : ''}`}
          style={{
            border: '2px dashed #cccccc',
            padding: '20px',
            textAlign: 'center',
            borderRadius: '5px',
            backgroundColor: '#f9f9f9',
          }}
        >
          <Input {...getInputProps()} />
          {isUploading ? (
            <Spinner color="primary" />
          ) : (
            <p>Drag and drop some files here, or click to select files</p>
          )}
        </div>

        {uploadError && <div className="text-danger mt-2">{uploadError}</div>}

        {/* Preview uploaded images */}
        <div className="preview-container mt-3 d-flex">
          {variantData.images.map((imageUrl, index) => (
            <div
              key={index}
              className="position-relative img-thumbnail mr-2"
              style={{ maxWidth: '100px', position: 'relative' }}
            >
              <img
                src={imageUrl}
                alt={`Uploaded ${index}`}
                className="img-fluid"
                style={{ maxWidth: '100px' }}
              />
              <Button
                size="sm"
                color="danger"
                className="position-absolute"
                style={{ top: '5px', right: '5px' }}
                onClick={() => handleDeleteImage(index)}
              >
                <FaTrash />
              </Button>
            </div>
          ))}
        </div>
      </FormGroup>
    </div>
  );
};

export default SingleVariantComponent;
