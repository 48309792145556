import { getMenusByUserId } from '../../Rest/api-menu'; // Import the API function

export const MENUITEMS = [
  {
    menutitle: "General",
    menucontent: "Dashboards,Widgets",
    Items: [
      {
        title: "Settings",
        icon: "settings", // You can replace this with the relevant icon
        path: `/setting/permissions`, // Static path for permissions settings
        active: false,
        type: "link",
        badge: false, // You can set this to true if needed
      },
      // You can add more static items here if needed
    ],
  },
];

export const fetchAndAddMenuItems = async () => {
  try {
    console.log('Fetching menu items from API...');
    const apiData = await getMenusByUserId(); // Fetch data from API
    console.log('Data fetched from API:', apiData);

    // Set all active properties to false initially
    MENUITEMS[0].Items.forEach(item => {
      item.active = false;
      if (item.children) {
        item.children.forEach(child => {
          child.active = false;
        });
      }
    });

    if (apiData && Array.isArray(apiData)) {
      apiData.forEach(apiItem => {
        // Set active to false for each apiItem
        apiItem.active = false;

        const existingItemIndex = MENUITEMS[0].Items.findIndex(item => item.title === apiItem.title);

        if (existingItemIndex > -1) {
          // If the item exists, merge the API item with the existing item
          MENUITEMS[0].Items[existingItemIndex] = {
            ...MENUITEMS[0].Items[existingItemIndex],
            ...apiItem,
            children: apiItem.children.map(child => ({
              ...child,
              path: `${process.env.PUBLIC_URL}${child.path}`,
              active: false, // Set active to false for each child as well
            })),
          };
        } else {
          // If the item does not exist, add it as a new item
          MENUITEMS[0].Items.push({
            ...apiItem,
            children: apiItem.children.map(child => ({
              ...child,
              path: `${process.env.PUBLIC_URL}${child.path}`,
              active: false, // Set active to false for each child
            })),
          });
        }
      });
      console.log('Updated MENUITEMS:', MENUITEMS);
    }
  } catch (error) {
    console.error('Failed to fetch and add menu items:', error);
  }
};

// Call this function when you need to load menu items, e.g., in a component's useEffect
fetchAndAddMenuItems();
